import React, {Component, useState,useEffect } from "react";

import io from "socket.io-client";




function Goldsilverprice() {

  
    const [liveData, setLiveData] = useState([]);

    useEffect(() => {
  
  
      // // Connect to the socket server
      // const socket = io('http://wssc.datakick.in:6003');
  
      // // Emit 'room' event to join the room
      // socket.emit('room', 'rate_SkyLive');
  
      // // Listen for 'LiveData' events in the specific room
      // socket.on('LiveData', (data) => {
      //     setLiveData((prevData) => [...prevData, data]);
      //     console.log('Received LiveData:', data);
      // });
  
      // // Clean up the socket connection on component unmount
      // return () => {
      //     socket.disconnect();
      // };
  
      const socket = io("https://goldapi.dialerp.com");
  
      socket.on("LiveData", (data) => {
        console.log("live data",data)
        const parsedData = data.map((item) => JSON.parse(item));
        setLiveData((prevData) => [parsedData, ...prevData.slice(0, 4)]);
        // console.log("Received LiveData:", parsedData);
      });
  
      return () => {
        socket.disconnect();
      };
    }, []);
  


  return (
    <>
    <div className="catelogue ctl">
        <section>
           
                <div className="row">
                    <h1>Gold Silver Prices</h1>

                  </div> 
                

    <div className="table-responsive">
    <table class="table table-striped">
  <thead class="thead-dark">
    <tr>
      <th class="bg-warning" scope="col">Name</th>
      <th class="bg-warning" scope="col">Bid</th>
      <th class="bg-warning" scope="col">Ask</th>
      <th class="bg-warning" scope="col">H|L</th>
    </tr>
  </thead>
  {liveData[0]?.map((data, i) => (
  <tbody>    
    <tr key={i} class="table-danger">
      <th scope="row">{data.symbol.toUpperCase()}</th>
      <th>₹{data.Bid}</th>
      <th>₹{data.Ask}</th>
      <td>{data.High}|{data.Low}</td>
    </tr>
   

  </tbody>
))}
</table>

    </div>
        </section>
      
      </div>
    </>
  );
}

export default Goldsilverprice;
