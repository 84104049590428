import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

import { Link } from "react-router-dom";

function Seller() {
  return (
    <>
    <div className="innerpage">
        <div><Header /></div>

       <div>
       <div className="appie-page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="appie-page-title-item">
            <h3 className="title">Savya Seller</h3>
         
            {/* <div className="thumb">
              <img src="assets/images/signup-thumb.png" alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*====== APPIE PAGE TITLE PART ENDS ======*/}



  {/*====== APPIE HOW IT WORK PART ENDS ======*/}
  <div className="appie-how-it-work-area pt-100 pb-90">
    <div className="container">
      <div className="row align-items-center">
      
        <div className="col-lg-12">
          <div className="appie-how-it-work-content">
            <h2 className="title text-center">Savya Seller</h2>
            <p className="text-center">For sellers looking to join a B2B jewelry portal, the platform should offer features and benefits that enhance their ability to reach new customers, streamline their sales processes, and grow their businesses. Here are key bullet points from the seller's perspective to encourage them to register:</p>
           
          </div>
        </div>
      </div>
      <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-bolt"></i>
                        </div>
                        <h4 class="title">Access to a Broad Buyer Network</h4>
                        <p>Connect with a wide network of verified buyers from around the world, expanding your market reach and potential customer base.</p>
                   
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-home"></i>
                        </div>
                        <h4 class="title">Increased Brand Visibility</h4>
                        <p>Enhance your brand's visibility through a platform that attracts a large audience of relevant B2B buyers, including retailers, wholesalers, and other businesses.</p>
                      
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-link"></i>
                        </div>
                        <h4 class="title">Streamlined Sales Process</h4>
                        <p>Utilize tools and features that simplify listing, managing inventory, processing orders, and tracking shipments, making the sales process more efficient.</p>
                       
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-bell"></i>
                        </div>
                        <h4 class="title">Marketplace Insights</h4>
                        <p>- Gain access to real-time analytics and insights on market trends, buyer behavior, and product performance, helping you make informed decisions to optimize your sales strategy.
</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-cog"></i>
                        </div>
                        <h4 class="title">Secure Payment Solutions</h4>
                        <p>Benefit from integrated secure payment gateways that ensure timely and safe transactions, reducing the risk of fraud and non-payment.
</p>
                      
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <h4 class="title">Customizable Storefronts</h4>
                        <p> Create customizable storefronts that allow you to showcase your products and brand story, providing a personalized shopping experience for buyers.</p>
                        
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                        <i class="fas fa-wreath"></i>
                        </div>
                        <h4 class="title">Product Promotion Tools</h4>
                        <p>Leverage promotional tools and opportunities such as featured listings, advertisements, and email marketing campaigns to increase product visibility and sales.</p>
                        
                    </div>
                </div>


                
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                        <i class="fas fa-volume"></i>
                        </div>
                        <h4 class="title">Direct Communication Channels</h4>
                        <p> Utilize built-in messaging and communication tools to directly connect with buyers, negotiate deals, and build lasting business relationships.</p>
                        
                    </div>
                </div>

                
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                        <i class="fas fa-star-half-alt"></i>
                        </div>
                        <h4 class="title">Feedback and Reviews</h4>
                        <p>Collect and display buyer feedback and reviews to build trust with potential customers and enhance your reputation on the platform.</p>
                        
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                        <i class="fas fa-wheat"></i>
                        </div>
                        <h4 class="title">Scalability</h4>
                        <p>Take advantage of a platform that supports businesses of all sizes, allowing you to scale your operations up or down based on your business needs and market demand.</p>
                        
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                        <i class="fas fa-user-headset"></i>
                        </div>
                        <h4 class="title">Support and Training</h4>
                        <p>Access dedicated support services and training materials to help you maximize the use of the platform and navigate any challenges.</p>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                        <i class="fas fa-star-of-david"></i>
                        </div>
                        <h4 class="title">Compliance and Dispute Resolution</h4>
                        <p>Rely on a platform that adheres to industry standards and regulations, with clear policies and mechanisms in place for dispute resolution and compliance issues.</p>
                        
                    </div>
                </div>

                <div class="col-lg-12 col-md-6 mt-80">
                <p className="text-center">By focusing on these features and benefits, a B2B jewelry portal can provide a compelling value proposition for sellers, encouraging them to register and actively engage with the platform.</p>
                </div>

            </div>


     
    </div>
  </div>


  <section>
    <div className="container">
    <div class="row justify-content-center">
      
    <div className="col-lg-12">
          <div className="appie-how-it-work-content" style={{marginBottom:50}}>
            <h2 className="title text-center">Pricing Plans</h2>
           
          </div>
        </div>
   
   <div class="col-lg-12 col-md-12">
   <div class="pricing-one__single pricing-one__single_2 active animated fadeInUp" style={{marginBottom:50}}>
         <div class="pricig-heading">
          <h3>Savya Seller</h3>
            <h6>Registration</h6>
            <div class="price-range">
               <span>Free</span>
            </div>
            <div class="pricig-body">   
       
            <ul>  <li><i class="fal fa-check"></i>25 Photos Upload</li>
               <li><i class="fal fa-check"></i> No Leads Gurantee</li>
               <li><i class="fal fa-check"></i> No brand marketing </li>
               <li><i class="fal fa-check"></i> No lead manager </li>
               <li><i class="fal fa-check"></i> No support</li>
            </ul>
           
           
         </div>
          
         </div>
         <div class="pricig-body">   
         <strong style={{color:'#A21E5B', fontSize:20, display:'block', marginBottom:20, fontWeight:600}}>Paid Membership Annual</strong>
            <ul> 
               <li><i class="fal fa-check"></i> 30000 +18% GST</li>
               <li><i class="fal fa-check"></i> Unlimited product photos uploading</li>
               <li><i class="fal fa-check"></i> Min 150 leads guaranted in year</li>
               <li><i class="fal fa-check"></i> Brand marketing</li>
               <li><i class="fal fa-check"></i> Lead manager</li>
               <li><i class="fal fa-check"></i> Support manager</li>
            </ul>
           
           
         </div>
      </div>
   </div>
   
</div>
    </div>
  </section>
  {/*====== APPIE HOW IT WORK PART ENDS ======*/}

  <section className="appie-download-area pt-150 pb-160 mb-90">
  <div className="container">
    <div className="row">
      <div className="col-lg-5">
        <div className="appie-download-content">
          <span>Download Our App</span>
          <h3 className="title">
            App is available <br />  on app store
          </h3>
          <div style={{display:'flex'}}> 
            <div style={{marginRight:20}}>
              
            <img src="assets/images/SS.png" alt="" className="logo" />
            <span style={{display:'block', textAlign:'center'}}>Android</span>
            </div>
            <div>
              
              <img src="assets/images/i_SS.png" alt="" className="logo" />
              <span style={{display:'block', textAlign:'center'}}>IOS</span>
              </div>
              </div>
          <ul>
            <li>
              <a className="item-2" href="assets/images/apk/savyaseller.apk" download 
              // onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.savya.sellers','_blank', 'rel=noopener noreferrer')}
              >
             <i className="fab fa-google-play" />
                <span>
                  Download for <span>Android</span>
                </span>
              </a>
            </li>
            <li>
              <Link onClick={()=>window.open('https://apps.apple.com/in/app/savya-seller/id6477292864','_blank', 'rel=noopener noreferrer')}>
                <i className="fab fa-apple" />
                <span>
                  Download for <span>iOS</span>
                </span>
              </Link>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="download-shape-1">
    <img src="assets/images/shape/shape-15.png" alt="" />
  </div>
  <div className="download-shape-2">
    <img src="assets/images/shape/shape-14.png" alt="" />
  </div>
  <div className="download-shape-3">
    <img src="assets/images/shape/shape-13.png" alt="" />
  </div>
</section>

<section>
    <div className="container">
    <div className="row mt-90">
      <div className="col-lg-12">
        <div className="appie-section-title text-center">
          <h3 className="appie-title">App showcase</h3>
        </div>
      </div>
    </div>
           <div className="row appie-showcase-slider">
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
        <img src="assets/images/seller/6.jpg" alt="" />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
            <img src="assets/images/seller/7.jpg" alt="" />
        
        </div>
      </div>
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
        <img src="assets/images/seller/3.jpg" alt="" />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
        <img src="assets/images/seller/4.jpg" alt="" />
        </div>
      </div>
    
    </div>
    </div>
</section>

       </div>
      
      <div>
        <Footer/>
      </div>
      </div>
    </>
  );
}

export default Seller;
