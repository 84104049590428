import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";


function Retailer() {
  return (
    <>
    <div className="innerpage">
        <div><Header /></div>

       <div>
       <div className="appie-page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="appie-page-title-item">
            <h3 className="title">Savya Buyer</h3>
         
            {/* <div className="thumb">
              <img src="assets/images/signup-thumb.png" alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*====== APPIE PAGE TITLE PART ENDS ======*/}



  {/*====== APPIE HOW IT WORK PART ENDS ======*/}
  <div className="appie-how-it-work-area pt-100 pb-90">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="how-it-work-thumb text-center">
            <img src="assets/images/how-it-work-thumb.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="appie-how-it-work-content">
            <h2 className="title">About Savya Buyer</h2>
            <p>
            In a B2B (business-to-business) jewellery application, buyers can experience several benefits, including:Wholesale Pricing: B2B buyers often access lower prices due to bulk purchasing, which can significantly increase their profit margins when they resell the items.Customization and Exclusivity: Many B2B jewellery suppliers offer customization options that are not available to retail customers. This can include custom designs, private labeling, and exclusive access to certain products.Streamlined Ordering Processes: B2B platforms typically provide tools and features that streamline the ordering process, such as inventory management systems, order tracking, and automated reordering systems, making it easier and faster for businesses to manage their operations.Access to a Wider Range of Products: B2B applications often provide access to a broader range of products than is available to individual consumers. This allows businesses to offer a more diverse selection to their customers.Networking Opportunities: B2B platforms can also serve as networking hubs, where businesses can connect with other retailers, suppliers, and distributors. This can open up opportunities for collaborations and partnerships.Marketing Support: Some B2B suppliers provide marketing and sales support to their buyers, including promotional materials, product training, and even co-branded marketing initiatives.Quality Assurance and Reliability: Dealing directly with manufacturers or authorized distributors through a B2B platform usually ensures higher levels of product quality and supply chain reliability.
            </p>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*====== APPIE HOW IT WORK PART ENDS ======*/}


  <section>
    <div className="container">
    <div class="row justify-content-center">
      
    <div className="col-lg-12">
          <div className="appie-how-it-work-content" style={{marginBottom:50}}>
            <h2 className="title text-center">Pricing Plans</h2>
           
          </div>
        </div>
   
   <div class="col-lg-12 col-md-12">
   <div class="pricing-one__single pricing-one__single_2 active animated fadeInUp" style={{marginBottom:50}}>
         <div class="pricig-heading">
         <h3>Savya Buyer</h3>
            <h6>Registration</h6>
            <div class="price-range">
               <span>Free</span>
            </div>
            <div class="pricig-body">   
        
            <ul>
            <li><i class="fal fa-check"></i> Limited excess to product photos</li>
               <li><i class="fal fa-check"></i> No direct excess to manufactures and wholesalers</li>
               <li><i class="fal fa-check"></i> No personal manager </li>
               <li><i class="fal fa-check"></i> No support manager </li>
               <li><i class="fal fa-check"></i> Can't share the products with your customers through pdf</li>
            </ul>
         
         </div>
          
         </div>
         <div class="pricig-body">   
         <strong style={{color:'#A21E5B', fontSize:20, display:'block', marginBottom:20, fontWeight:600}}>Paid Membership Annual</strong>
            <ul>
           
            
               <li><i class="fal fa-check"></i> 12000 +18 gst</li>
               <li><i class="fal fa-check"></i> Unlimited excess to product photos</li>
               <li><i class="fal fa-check"></i> Direct excess to manufactures and wholesalers</li>
               <li><i class="fal fa-check"></i> Personal manager </li>
               <li><i class="fal fa-check"></i> Support manger</li>
               <li><i class="fal fa-check"></i> Can share the products to your customers thourgh pdf</li>
            </ul>
         
         </div>
      </div>
   </div>
   
</div>
    </div>
  </section>
  <section className="appie-download-area appie-download-area2 pt-150 pb-160 mb-90">
  <div className="container">
    <div className="row">
      <div className="col-lg-5">
        <div className="appie-download-content">
          <span>Download Our App</span>
          <h3 className="title">
            App is available <br /> on app store
          </h3>
          <div style={{display:'flex'}}> 
            <div style={{marginRight:20}}>
              
            <img src="assets/images/SB.png" alt="" className="logo" />
            <span style={{display:'block', textAlign:'center'}}>Android</span>
            </div>
            <div>
              
              <img src="assets/images/i_SB.png" alt="" className="logo" />
              <span style={{display:'block', textAlign:'center'}}>IOS</span>
              </div>
              </div>

          <ul>
            <li>
              <a href="assets/images/apk/savyaretail.apk" download className="item-2" 
              // onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.savya.buyer','_blank', 'rel=noopener noreferrer')}
              >
                <i className="fab fa-google-play" />
                <span>
                  Download for <span>Android</span>
                </span>
              </a>
            </li>
            <li>
              <Link onClick={()=>window.open('https://apps.apple.com/in/app/savya-buyer/id6499137711','_blank', 'rel=noopener noreferrer')}>
                <i className="fab fa-apple" />
                <span>
                  Download for <span>iOS</span>
                </span>
              </Link>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="download-shape-1">
    <img src="assets/images/shape/shape-15.png" alt="" />
  </div>
  <div className="download-shape-2">
    <img src="assets/images/shape/shape-14.png" alt="" />
  </div>
  <div className="download-shape-3">
    <img src="assets/images/shape/shape-13.png" alt="" />
  </div>
</section>

<section>
    <div className="container">
    <div className="row mt-90">
      <div className="col-lg-12">
        <div className="appie-section-title text-center">
          <h3 className="appie-title">App showcase</h3>
        </div>
      </div>
    </div>
     
    <div className="row appie-showcase-slider">
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
        <img src="assets/images/buyer/3.jpg" alt="" />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
            <img src="assets/images/buyer/4.jpg" alt="" />
        
        </div>
      </div>
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
        <img src="assets/images/buyer/5.jpg" alt="" />
        </div>
      </div>
      <div className="col-lg-3">
        <div className="appie-showcase-item mt-30">
        <img src="assets/images/buyer/7.jpg" alt="" />
        </div>
      </div>
    
    </div>
    </div>
</section>
       </div>
      
      <div>
        <Footer/>
      </div>
      </div>
    </>
  );
}

export default Retailer;
