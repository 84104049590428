import React, {Component, useState, } from "react";
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css'

const images = [
  {
    original: "assets/images/ring/1.jpg",
    thumbnail: "assets/images/ring/1.jpg",
  },
  {
    original: "assets/images/ring/2.jpg",
    thumbnail: "assets/images/ring/2.jpg",
  },
  {
    original: "assets/images/ring/4.jpg",
    thumbnail: "assets/images/ring/4.jpg",
  },
];


function Catelogue() {

  


  return (
    <>
    <div className="catelogue">
        <section>
           
                <div className="row">
                    <h1>Rings to cherish forever</h1>

                    <img
                        src="assets/images/border.svg"
                        alt=""
                      />


                </div> 
                <div className="headcomp">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="loggo">
                      <img
                        src="assets/images/dummylogo.svg"
                        alt=""
                      />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div class="companydetails">
                        <strong>Mangalam Jewellers</strong>
                        <p>+91-9999999999</p>
                        <p>info@mangalamjewellers.com</p>
                        <div className="border"></div>
                        <p>Store Address : G-10&11, Market Sector 18, Noida, UP 201301 </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="imagedetails">
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-12">
                       <ImageGallery items={images} thumbnailPosition={"right"} showNav="false" showPlayButton="false" />
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="rightblockk">
                      <div className="titles">
                        <b>CZ Ladies Ring</b>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-md-4 col-md-4">
                          <div className="descriptionlist">
                          <h3>Product Details</h3>
                          <ul>
                            <li><span>Design Id</span> <small>:</small> <span>60</span></li>
                            <li><span>Design Number.</span> <small>:</small> <span>gr 11</span></li>
                            <li><span>Gross Weight</span><small>:</small> <span>11.500 g</span></li>
                            <li><span>Ring Size</span> <small>:</small> <span>11 mm</span></li>
                          </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                        <div className="descriptionlist">
                          <h3>Gold Details</h3>
                          <ul>
                            <li><span>Gold Color</span> <small>:</small> <span>Yellow Gold</span></li>
                            <li><span>Stamp</span> <small>:</small> <span>18kt</span></li>
                          </ul>
                          </div>
                        </div>

                        <div className="col-md-4">
                        <div className="descriptionlist">
                          <h3>CZ Details</h3>
                          <ul className="no-border">
                            <li><span>CZ Weight</span> <small>:</small> <span>0.200 g</span></li>
                            <li><span>No, of CZ</span> <small>:</small> <span>50</span></li>
                          </ul>
                          </div>
                        </div>
                      </div>

                      <div className="bottombarr">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="crumb">
                              <p><span>Gold Jewellery</span>/<span>Gold Ring</span>/<span className="blue">CZ Ladies Ring</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                              <button class="btn btn-theme btnborder" data-toggle="modal" data-target="#exampleModal">Generate Query</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                </div>


                <div className="imagedetails">
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-12">
                       <ImageGallery items={images} thumbnailPosition={"right"} showNav="false" showPlayButton="false" />
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="rightblockk">
                      <div className="titles">
                        <b>CZ Ladies Ring</b>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-md-4 col-md-4">
                          <div className="descriptionlist">
                          <h3>Product Details</h3>
                          <ul>
                            <li><span>Design Id</span> <small>:</small> <span>60</span></li>
                            <li><span>Design Number.</span> <small>:</small> <span>gr 11</span></li>
                            <li><span>Gross Weight</span><small>:</small> <span>11.500 g</span></li>
                            <li><span>Ring Size</span> <small>:</small> <span>11 mm</span></li>
                          </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                        <div className="descriptionlist">
                          <h3>Gold Details</h3>
                          <ul>
                            <li><span>Gold Color</span> <small>:</small> <span>Yellow Gold</span></li>
                            <li><span>Stamp</span> <small>:</small> <span>18kt</span></li>
                          </ul>
                          </div>
                        </div>

                        <div className="col-md-4">
                        <div className="descriptionlist">
                          <h3>CZ Details</h3>
                          <ul className="no-border">
                            <li><span>CZ Weight</span> <small>:</small> <span>0.200 g</span></li>
                            <li><span>No, of CZ</span> <small>:</small> <span>50</span></li>
                          </ul>
                          </div>
                        </div>
                      </div>

                      <div className="bottombarr">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="crumb">
                              <p><span>Gold Jewellery</span>/<span>Gold Ring</span>/<span className="blue">CZ Ladies Ring</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                              <button class="btn btn-theme btnborder" data-toggle="modal" data-target="#exampleModal">Generate Query</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                </div>

                <div className="imagedetails">
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-12">
                       <ImageGallery items={images} thumbnailPosition={"right"} showNav="false" showPlayButton="false" />
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="rightblockk">
                      <div className="titles">
                        <b>CZ Ladies Ring</b>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-md-4 col-md-4">
                          <div className="descriptionlist">
                          <h3>Product Details</h3>
                          <ul>
                            <li><span>Design Id</span> <small>:</small> <span>60</span></li>
                            <li><span>Design Number.</span> <small>:</small> <span>gr 11</span></li>
                            <li><span>Gross Weight</span><small>:</small> <span>11.500 g</span></li>
                            <li><span>Ring Size</span> <small>:</small> <span>11 mm</span></li>
                          </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                        <div className="descriptionlist">
                          <h3>Gold Details</h3>
                          <ul>
                            <li><span>Gold Color</span> <small>:</small> <span>Yellow Gold</span></li>
                            <li><span>Stamp</span> <small>:</small> <span>18kt</span></li>
                          </ul>
                          </div>
                        </div>

                        <div className="col-md-4">
                        <div className="descriptionlist">
                          <h3>CZ Details</h3>
                          <ul className="no-border">
                            <li><span>CZ Weight</span> <small>:</small> <span>0.200 g</span></li>
                            <li><span>No, of CZ</span> <small>:</small> <span>50</span></li>
                          </ul>
                          </div>
                        </div>
                      </div>

                      <div className="bottombarr">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="crumb">
                              <p><span>Gold Jewellery</span>/<span>Gold Ring</span>/<span className="blue">CZ Ladies Ring</span></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                              <button class="btn btn-theme btnborder" data-toggle="modal" data-target="#exampleModal">Generate Query</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                </div>

            
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Product Query</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <div className="row forrm">
                        <div class="col-md-12">
                          <label>Select Product</label>
                          <input
                            type="text"
                           className="form-control"
                            placeholder="CZ Ladies Ring" disabled readOnly
                          />                        
                        </div>

                        <div className="col-md-12">
                          
                        <label>Name<span>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                          />
                        </div>
                        <div className="col-md-12">
                        <label>Mobile Number<span>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="9999999999"
                          />
                        </div>
                        <div className="col-md-12">
                        <label>Tell us about your query<span>*</span></label>
                          <textarea
                            className="form-control"
                            rows="3"
                            placeholder="Mention your query here"
                          ></textarea>
                        </div>
                     
                      </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>

        </section>
      
      </div>
    </>
  );
}

export default Catelogue;
