import React, { Component, useState, useEffect, useRef } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";

import io from "socket.io-client";

function Home() {
  const [liveData, setLiveData] = useState([]);
  const oldDataRef = useRef([]);
  useEffect(() => {
    const socket = io("https://goldapi.dialerp.com");

    socket.on("LiveData", (data) => {
      const parsedData = data.map((item) => JSON.parse(item));

      const updatedData = parsedData.map((newItem, index) => {
        const oldItem = oldDataRef.current[index] || {};
        return {
          ...newItem,
          BidChange:
            newItem.Bid > (oldItem.Bid || 0)
              ? "increased"
              : newItem.Bid < (oldItem.Bid || 0)
              ? "decreased"
              : "same",
          AskChange:
            newItem.Ask > (oldItem.Ask || 0)
              ? "increased"
              : newItem.Ask < (oldItem.Ask || 0)
              ? "decreased"
              : "same",
        };
      });

      setLiveData(updatedData);
      oldDataRef.current = parsedData;
      console.log();
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  // let authToken =
  //   "SOGjhYKNYtck4teM3O76aUKxHv8m58kd5OD/oDuEoL8U0Ln3o2IySf4aAvFvL9Av3CtbIgShGhcfw2CBB3900abZKy6nPcshKOdge5zqnyBiJ2NJsMFQ+Iti63mrxBduvvxOjlK+Lvykor08Gm+4wQ==";
  let authToken =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzJhMTRhMjFmZGU4NTM3YWI3OGQxMDgiLCJzZXNzaW9uIjoiNjcyYTE0YTIxZmRlODUzN2FiNzhkMTBhIiwibmFtZSI6IlNhdnlhIEpld2VscyIsImVtYWlsIjoiaW5mb0BzYXZ5YWpld2Vsc2J1c2luZXNzLmNvbSIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MmExNGEyMWZkZTg1MzdhYjc4ZDEwOCIsImlhdCI6MTczMDgxMTA0MiwiZXhwIjoxODg4NTk5MDQyfQ.dhpu9NtuPtCHY0QGMPIBR90oWJRCPTqSX1ppDRppQr1sH6VcdW_TA39gW1oUusIgg-ZlfTyTErathbrbmjE6SdXclvs50KV9Zk9OJlnUDMS-ki1DFfSrqqlamVECCt3prPTsVQDBnimrmLXKwGw6ik8qm2CuVmH0lP_7ZzuMj1Iw5we7x96VKOVhzjJV5ZakJf4lyiP7bIoJAqUdoXD2H-XGQ1VMk5UgCzuezqrw1pAcYZ3N_SPBLyOSGj5Z9YeSPIhKaurXC_GjTUSeECOjEXJfH2pmyAx-M5_xfrmWQAFD9KaQeE65WQeKkcyDmvH-1Zi2r9B0jMjokJDJAjqWuw";
  const [Name, setName] = useState("");
  //const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Comments, setComments] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [error, setError] = useState("");
  // const ContactPara = {
  //   templateID: "2",
  //   _1: Name,
  //   _2: Email,
  //   _3: Mobile,
  //   _4: Comments,
  // };
  // function fun_Contact() {
  //   if (Name.trim() === "") {
  //     setError("Please enter name.");
  //     document.getElementById("txtFirstName").focus();
  //     return;
  //   }

  //   if (Email.trim() === "") {
  //     setError("Please enter email id.");
  //     document.getElementById("txtEmail").focus();
  //     return;
  //   } else if (!emailRegex.test(Email.trim())) {
  //     setError("Please enter valid email id.");
  //     document.getElementById("txtEmail").focus();
  //     return;
  //   } else if (Mobile.trim() === "") {
  //     setError('Please enter mobile no."');
  //     document.getElementById("txtmobile").focus();
  //     return;
  //   } else if (Comments.trim() === "") {
  //     setError('Please enter your message"');
  //     document.getElementById("txtmessage").focus();
  //     return;
  //   }

  //   fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
  //     method: "POST",
  //     headers: {
  //       "Authorization-Token": authToken,
  //       Accept: "application/json, text/plain",
  //       "Content-Type": "application/json;charset=UTF-8",
  //     },
  //     body: JSON.stringify(ContactPara),
  //   }).then((result) => {
  //     result.json().then((resp) => {
  //       debugger;
  //       if (resp.hasErrors === false) {
  //         alert("Data save successfully.");
  //         // window.location.reload(true)
  //       } else {
  //         alert(resp.errors);
  //       }
  //     });
  //   });
  // }
  const ContactPara = {
    name: Name,
    mobile: Number(Mobile),
    email: Email,
    query: Comments,
    domain: "savyajewelsbusiness.com",
  };
  const fun_Contact = async () => {
    if (Name.trim() === "") {
      setError("Please enter name.");
      document.getElementById("txtFirstName").focus();
      return;
    }
    if (Email.trim() === "") {
      setError("Please enter email id.");
      document.getElementById("txtEmail").focus();
      return;
    } else if (!emailRegex.test(Email.trim())) {
      setError("Please enter valid email id.");
      document.getElementById("txtEmail").focus();
      return;
    } else if (Mobile.trim() === "") {
      setError('Please enter mobile no."');
      document.getElementById("txtmobile").focus();
      return;
    } else if (Comments.trim() === "") {
      setError('Please enter your message"');
      document.getElementById("txtmessage").focus();
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      authorization: authToken,
    };
    try {
      //"https://crmleads.erpthemes.com/save-result",
      const response = await fetch(
        "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=27&tempName=savya_contact_us",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(ContactPara),
        }
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const result = await response.json();
      if (result && result.resp) {
        if (result.resp?.status) {
          alert(result.resp?.message);
          window.scrollTo(0, 0);
         // navigate('/'); // Redirect to the base URL (home page)
          window.location.reload();
        } else {
          alert(result.resp?.message);
        }
      }
    } catch (error) {
      console.error("Error during fetch:", error.message);
    }
  };
  return (
    <>
      <div>
        <Header />
      </div>

      <div>
        <section className="appie-hero-area-2">
          {/* <img src="assets/images/banner2.webp" alt="" /> */}
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="appie-hero-content-2">
                  <h1 className="appie-title text-center">
                    Welcome to Savya Jewels—your{" "}
                    <span>trusted partner in the B2B jewellery market</span>
                  </h1>
                  <p className="text-center">
                    Savya Jewels is trusted partner in the B2B jewellery market,
                    designed to streamline and enhance the wholesale jewellery
                    purchasing experience. We specialize in connecting jewellery
                    sellers and buyers through a sophisticated digital platform
                    that integrates the best of technology and industry
                    expertise.
                  </p>
                  <div className="homebtn">
                    <a class="main-btn" href="#">
                      {" "}
                      <img
                        src="assets/images/savya_sellers_logo.svg"
                        alt=""
                        className="logo"
                      />
                      {/* Savya Seller */}
                    </a>
                    <a class="main-btn" href="#">
                      <img
                        src="assets/images/retailer-logo.png"
                        alt=""
                        className="logo"
                      />
                      {/* Savya Buyer */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-shape-1">
            <img src="assets/images/shape/shape-9.png" alt="" />
          </div>
          <div className="hero-shape-2">
            <img src="assets/images/shape/shape-10.png" alt="" />
          </div>
          <div className="hero-shape-3">
            <img src="assets/images/shape/shape-11.png" alt="" />
          </div>
          <div className="hero-shape-4">
            <img src="assets/images/shape/shape-12.png" alt="" />
          </div>
        </section>
<section className="pt-50 pb-50">
  <div className="container">
  <div className="row">
              <div
                className="appie-section-title text-center"
                style={{ width: "100%" }}
              >
                <h3 className="appie-title">Gold and Silver Prices (Live)</h3>
</div>
    <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="thead-dark">
    <tr>
                      <th scope="col" style={{ backgroundColor: "#A21E5B", width:'25%' }}>
                        Name
                      </th>
                      <th scope="col" style={{ backgroundColor: "#A21E5B", width:'25%' }}>
                        Bid
                      </th>
                      <th scope="col" style={{ backgroundColor: "#A21E5B", width:'25%' }}>
                        Ask
                      </th>
                      <th scope="col" style={{ backgroundColor: "#A21E5B", width:'25%' }}>
                        H|L
                      </th>
    </tr>
  </thead>
  

  <tbody>    
                    {liveData.map((data, i) => (
                      <tr class="table-danger"
                        key={i}
                      >
      <th scope="row">{data.symbol.toUpperCase()}</th>
                        <th 
                          className={
                            data.BidChange === "increased"
                              ? "text-success"
                              : data.BidChange === "decreased"
                              ? "text-danger"
                              : "text-black"
                          }
                          >
                          ₹{data.Bid}
                        </th>
                        <th  className={
                            data.BidChange === "increased"
                              ? "text-success"
                              : data.BidChange === "decreased"
                              ? "text-danger"
                              : "text-black"
                          }>
                          ₹{data.Ask}
                        </th>
                        <td>
                          {data.High} | {data.Low}
                        </td>
    </tr>
                    ))}
  </tbody>

</table>

    </div>
    </div> 
                
  </div>
</section>
        <section className="contactstrip">
          <div className="container">
            <div className="contact-form">
              <h4>Let’s Connect</h4>

              {/* <form action="#" className="gane-form"> */}
                <div className="row">
                  <div class="col-md-3">
                    <input
                      type="text"
                      value={Name}
                      className={`form-control ${
                        error && Name.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setName(e.target.value)}
                      id="txtFirstName"
                      placeholder="Name"
                    />
                    {error && Name.trim() === "" && (
                      <div className="invalid-feedback">Please enter name.</div>
                    )}
                  </div>

                  <div className="col-md-3">
                    <input
                      type="tel"
                      value={Mobile}
                      className={`form-control ${
                        error && Mobile.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setMobile(e.target.value)}
                      id="txtmobile"
                      placeholder="Mobile"
                    />
                    {error && Mobile.trim() === "" && (
                      <div className="invalid-feedback">
                        Please enter mobile no.
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <input
                      type="email"
                      value={Email}
                      className={`form-control ${
                        error && Email.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setEmail(e.target.value)}
                      id="txtEmail"
                      placeholder="Your Email"
                    />
                    {error && Email.trim() === "" && (
                      <div className="invalid-feedback">
                        Please enter email id.
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      value={Comments}
                      className={`form-control ${
                        error && Comments.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setComments(e.target.value)}
                      rows="3"
                      id="txtmessage"
                      placeholder="Enter your message"
                    />
                    {error && Comments.trim() === "" && (
                      <div className="invalid-feedback">
                        Please enter your message.
                      </div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-theme"
                      type="submit"
                      onClick={fun_Contact}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              {/* </form> */}
            </div>
          </div>
        </section>
        <section className="appie-download-3-area pt-100" id="download">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">Our Apps</h3>
                  <p>Download our apps today.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="content">
                    <img
                      src="assets/images/savya_sellers_logo.svg"
                      alt=""
                      className="logo"
                    />
                    <h4 className="title">Savya Seller</h4>
                    <p>Download our Savya Seller app today.</p>
                    <br />
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: 20 }}>
                        <img
                          src="assets/images/SS.png"
                          alt=""
                          className="logo"
                        />
                        <span style={{ display: "block", textAlign: "center" }}>
                          Android
                        </span>
                      </div>
                      <div>
                        <img
                          src="assets/images/i_SS.png"
                          alt=""
                          className="logo"
                        />
                        <span style={{ display: "block", textAlign: "center" }}>
                          IOS
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <a href="assets/images/apk/savyaseller.apk" download className="main-btn"
                        // className="main-btn"
                        // onClick={() =>
                        //   window.open(
                        //     "https://play.google.com/store/apps/details?id=com.savya.sellers",
                        //     "_blank",
                        //     "rel=noopener noreferrer"
                        //   )
                        // }
                      >
                        <i className="fab fa-google-play" />
                        Download for Android
                      </a>

                      <Link
                        className="main-btn"
                        onClick={() =>
                          window.open(
                            "https://apps.apple.com/in/app/savya-seller/id6477292864",
                            "_blank",
                            "rel=noopener noreferrer"
                          )
                        }
                      >
                        <i className="fab fa-apple" />
                        Download for IOS
                      </Link>
                    </div>
                  </div>
                  <div className="thumb text-center">
                    <img src="assets/images/download-thumb-1.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="content">
                    <img
                      src="assets/images/retailer-logo.png"
                      alt=""
                      className="logo"
                    />
                    <h4 className="title">Savya Buyer</h4>
                    <p>Download our Savya Buyer app today.</p>
                    <br />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: 20 }}>
                        <img
                          src="assets/images/SB.png"
                          alt=""
                          className="logo"
                        />
                        <span style={{ display: "block", textAlign: "center" }}>
                          Android
                        </span>
                      </div>
                      <div>
                        <img
                          src="assets/images/i_SB.png"
                          alt=""
                          className="logo"
                        />
                        <span style={{ display: "block", textAlign: "center" }}>
                          IOS
                        </span>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <a href="assets/images/apk/savyaretail.apk" download className="main-btn"
                        // className="main-btn"
                        // onClick={() =>
                        //   window.open(
                        //     "https://play.google.com/store/apps/details?id=com.savya.buyer",
                        //     "_blank",
                        //     "rel=noopener noreferrer"
                        //   )
                        // }
                      >
                        <i className="fab fa-google-play" />
                        Download for Android
                      </a>

                      <Link
                        className="main-btn"
                        onClick={() =>
                          window.open(
                            "https://apps.apple.com/in/app/savya-buyer/id6499137711",
                            "_blank",
                            "rel=noopener noreferrer"
                          )
                        }
                      >
                        <i className="fab fa-apple" />
                        Download for IOS
                      </Link>
                    </div>
                  </div>
                  <div className="thumb text-right">
                    <img src="assets/images/download-thumb-2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="appie-features-area-2 pt-90 pb-100" id="features">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="appie-section-title appie-section-title-2 text-center">
                  <h3 className="appie-title">
                    Wherever you need <br /> us the most
                  </h3>
                </div>
              </div>
            </div>
            <div className="row mt-30 align-items-center">
              <div className="col-lg-6">
                <div className="appie-features-boxes">
                  <div className="appie-features-box-item">
                    <h4 className="title">Well Integrated</h4>
                  </div>
                  <div className="appie-features-box-item item-2">
                    <h4 className="title">Clean and modern Design</h4>
                  </div>
                  <div className="appie-features-box-item item-3">
                    <h4 className="title">Easy to use</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="appie-features-thumb wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src="assets/images/features-thumb-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="features-shape-1">
            <img src="assets/images/shape/shape-15.png" alt="" />
          </div>
          <div className="features-shape-2">
            <img src="assets/images/shape/shape-14.png" alt="" />
          </div>
          <div className="features-shape-3">
            <img src="assets/images/shape/shape-13.png" alt="" />
          </div>
        </section>

        <section
          className="appie-features-area appie-features-6-area pb-80 pt-90 "
          id="features"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">App showcase</h3>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="appie-features-tabs-btn">
                  <div
                    className="nav nav-pills text-center"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-home"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <img
                        src="assets/images/savya_sellers_logo.svg"
                        alt=""
                        className="logo"
                        style={{ display: "block", margin: "0 auto" }}
                      />{" "}
                      <span className="tbtn"> Click Here</span>
                    </a>
                    <a
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-profile"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <img
                        src="assets/images/retailer-logo.png"
                        alt=""
                        className="logo"
                        style={{ display: "block", margin: "0 auto" }}
                      />
                      <span className="tbtn"> Click Here</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="row appie-showcase-slider">
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/seller/6.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/seller/7.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/seller/3.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/seller/4.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="row appie-showcase-slider">
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/buyer/3.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/buyer/4.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/buyer/5.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="appie-showcase-item mt-30">
                          <img src="assets/images/buyer/7.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="features-shape-1">
            <img src="assets/images/shape/shape-6.png" alt="" />
          </div>
          <div className="features-shape-2">
            <img src="assets/images/shape/shape-7.png" alt="" />
          </div>
          <div className="features-shape-3">
            <img src="assets/images/shape/shape-8.png" alt="" />
          </div>
        </section>

        <section>
          <div className="container">
            <div class="row justify-content-center">
              <div className="col-lg-12">
                <div
                  className="appie-how-it-work-content"
                  style={{ marginBottom: 50 }}
                >
                  <h2 className="title text-center">Pricing Plans</h2>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div
                  class="pricing-one__single pricing-one__single_2 active animated fadeInUp"
                  style={{ marginBottom: 50 }}
                >
                  <div class="pricig-heading">
                    <h3>Savya Seller</h3>
                    <h6>Registration</h6>
                    <div class="price-range">
                      <span>Free</span>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        {" "}
                        <li>
                          <i class="fal fa-check"></i>25 Photos Upload
                        </li>
                        <li>
                          <i class="fal fa-check"></i> No Leads Gurantee
                        </li>
                        <li>
                          <i class="fal fa-check"></i> No brand marketing{" "}
                        </li>
                        <li>
                          <i class="fal fa-check"></i> No lead manager{" "}
                        </li>
                        <li>
                          <i class="fal fa-check"></i> No support
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="pricig-body">
                    <strong
                      style={{
                        color: "#A21E5B",
                        fontSize: 20,
                        display: "block",
                        marginBottom: 20,
                        fontWeight: 600,
                      }}
                    >
                      Paid Membership Annual
                    </strong>
                    <ul>
                      <li>
                        <i class="fal fa-check"></i> 30000 +18% GST
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Unlimited product photos
                        uploading
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Min 150 leads guaranted in
                        year
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Brand marketing
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Lead manager
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Support manager
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div
                  class="pricing-one__single pricing-one__single_2 active animated fadeInUp"
                  style={{ marginBottom: 50 }}
                >
                  <div class="pricig-heading">
                    <h3>Savya Buyer</h3>
                    <h6>Registration</h6>
                    <div class="price-range">
                      <span>Free</span>
                    </div>
                    <div class="pricig-body">
                      <ul>
                        <li>
                          <i class="fal fa-check"></i> Limited excess to product
                          photos
                        </li>
                        <li>
                          <i class="fal fa-check"></i> No direct excess to
                          manufactures and wholesalers
                        </li>
                        <li>
                          <i class="fal fa-check"></i> No personal manager{" "}
                        </li>
                        <li>
                          <i class="fal fa-check"></i> No support manager{" "}
                        </li>
                        <li>
                          <i class="fal fa-check"></i> Can't share the products
                          with your customers through pdf
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="pricig-body">
                    <strong
                      style={{
                        color: "#A21E5B",
                        fontSize: 20,
                        display: "block",
                        marginBottom: 20,
                        fontWeight: 600,
                      }}
                    >
                      Paid Membership Annual
                    </strong>
                    <ul>
                      <li>
                        <i class="fal fa-check"></i> 12000 +18 gst
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Unlimited excess to product
                        photos
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Direct excess to
                        manufactures and wholesalers
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Personal manager{" "}
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Support manger
                      </li>
                      <li>
                        <i class="fal fa-check"></i> Can share the products to
                        your customers thourgh pdf
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="appie-counter-area pt-90 pb-190">
          <div className="container">
            {/* <div className="row">
      <div className="col-lg-12">
        <div className="appie-section-title">
          <h3 className="appie-title text-center">How does it work</h3>
          
        </div>
      </div>
    </div> */}
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div
                  className="appie-single-counter mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="counter-content">
                    <div className="icon">
                      <img src="assets/images/icon/counter-icon-1.svg" alt="" />
                    </div>
                    <h3 className="title">
                      <span className="counter-item">45</span>k+
                    </h3>
                    <p>Users</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="counter-content">
                    <div className="icon">
                      <img src="assets/images/icon/counter-icon-2.svg" alt="" />
                    </div>
                    <h3 className="title">
                      <span className="counter-item">50</span>+
                    </h3>
                    <p>Awards</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="counter-content">
                    <div className="icon">
                      <img src="assets/images/icon/counter-icon-3.svg" alt="" />
                    </div>
                    <h3 className="title">
                      <span className="counter-item">9K</span>+
                    </h3>
                    <p>Installation</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="800ms"
                >
                  <div className="counter-content">
                    <div className="icon">
                      <img src="assets/images/icon/counter-icon-4.svg" alt="" />
                    </div>
                    <h3 className="title">
                      <span className="counter-item">100</span>+
                    </h3>
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="appie-sponser-area pb-100 pt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">Our Partners</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-sponser-box d-flex justify-content-center">
                  <div className="sponser-item">
                    <img src="assets/images/clients/1.png" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/2.jpeg" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/3.jpg" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/4.png" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/5.jpeg" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/6.png" alt="" />
                  </div>
                  <div className="sponser-item" style={{ background: "#000" }}>
                    <img src="assets/images/clients/21.jpeg" alt="" />
                  </div>
                </div>
                <div className="appie-sponser-box item-2 d-flex justify-content-center">
                  <div className="sponser-item">
                    <img src="assets/images/clients/8.jpeg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/9.png" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/10.jpeg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/11.jpeg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/12.jpeg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/13.png" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/14.jpg" alt="" />
                  </div>
                </div>

                <div className="appie-sponser-box item-2 d-flex justify-content-center">
                  <div className="sponser-item">
                    <img src="assets/images/clients/15.jpg" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/16.jpg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/17.jpeg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/18.jpeg" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/19.png" alt="" />
                  </div>
                  <div className="sponser-item">
                    <img src="assets/images/clients/20.jpeg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/22.jpeg" alt="" />
                  </div>

                  <div className="sponser-item">
                    <img src="assets/images/clients/23.jpeg" alt="" />
                  </div>
                  
                  <div className="sponser-item">
                    <img src="assets/images/clients/24.jpeg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sponser-shape">
            <img src="assets/images/sponser-shape.png" alt="" />
          </div>
        </section>
        {/* 
<div className="appie-testimonial-about-area pb-45">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-5">
        <div className="appie-section-title text-center">
          <h3 className="appie-title">What clients say about us</h3>
          <p>The app provides design and digital marketing.</p>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-lg-10">
        <div className="testimonial-about-slider-active">
          <div className="testimonial-box">
            <div className="icon">
              <i className="fas fa-quote-left" />
            </div>
            <div className="ratings-icon">
              <ul>
                <li>
                  <i className="fas fa-star" />
                </li>
                <li>
                  <i className="fas fa-star" />
                </li>
                <li>
                  <i className="fas fa-star" />
                </li>
                <li>
                  <i className="fas fa-star" />
                </li>
                <li>
                  <i className="fas fa-star" />
                </li>
              </ul>
            </div>
            <p>
              Twit some dodgy chav he legged it crikey blatant starkers pukka
              show off show off pick your nose and blow off morish bum bag boot
              quaint, Oxford off his nut I bugger up the kyver brilliant bits
              and bobs haggle buggered.
            </p>
          </div>
     
        </div>
      </div>
      <div className="col-lg-4 col-md-8 col-sm-10">
        <div className="testimonial-box-about-slider-small-active">
          <div className="item">
            <div className="thumb">
              <img src="assets/images/testimonial-user-1.png" alt="" />
            </div>
            <div className="content text-center">
              <h5 className="title">Bodrum Salvador</h5>
              <span>Product Designer</span>
            </div>
          </div>
   
        </div>
      </div>
    </div>
  </div>
</div> */}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
